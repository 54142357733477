import React from 'react'
import Dollar from '../../assets/dollar.inline.svg'
import GreyDollar from '../../assets/dollar-grey.inline.svg'
import Hourglass from '../../assets/hourglass.inline.svg'
import GreyHourglass from '../../assets/hourglass-grey.inline.svg'
import User from '../../assets/user.inline.svg'
import GreyUser from '../../assets/user-grey.inline.svg'
import { IconScaleWrapper, GreyCircle, ColorCircle } from './style'

interface Props {
  type: string
  value: number
}

const typesMapping = {
  money: {
    normal: <Dollar />,
    grey: <GreyDollar />
  },
  time: {
    normal: <Hourglass />,
    grey: <GreyHourglass />
  },
  engagement: {
    normal: <User />,
    grey: <GreyUser />
  }
}

const iteratee = [1, 2, 3, 4, 5]
const IconScale = ({ type, value }: Props) => {
  return (
    <IconScaleWrapper>
      {iteratee.map(id =>
        id <= value ? (
          <ColorCircle key={`scale_elem_${id}`}>{typesMapping[type].normal}</ColorCircle>
        ) : (
          <GreyCircle key={`scale_elem_${id}`}>{typesMapping[type].grey}</GreyCircle>
        )
      )}
    </IconScaleWrapper>
  )
}

export default IconScale
