import * as React from 'react'
import { Link, graphql } from 'gatsby'
import IndexLayout from '../layouts'
import IconLink from '../components/Common/IconLink'
import IconScale from '../components/IconScale'
import {
  RecoTemplateWrapper,
  RecoTemplateInner,
  RecoHeader,
  ScalesWrapper,
  SeeMoreWrapper,
  SeeMoreGrid,
  ImplementationHeader,
  LinkWrapper,
  Description,
  Subtitle,
  MDWrapper
} from './style'
import { RecoIcon, RecoCard, RecoCardTitle, RecoCardSubtitle } from '../style/result-style'

interface Node {
  node: {
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
      description: string
      topic: string
      subtitle: string
      icon: {
        publicURL: string
      }
    }
  }
}

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
        topic: string
        subtitle: string
        description: string
        time: number
        money: number
        engagement: number
        disableImplementationTip?: boolean
        icon: {
          publicURL: string
        }
      }
      fields: {
        slug: string
      }
    }
    allMarkdownRemark: {
      edges: Array<Node>
    }
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  const { title, subtitle, description, topic, icon, time, money, engagement, disableImplementationTip } = data.markdownRemark.frontmatter
  const { slug } = data.markdownRemark.fields
  const recommendations = data.allMarkdownRemark.edges
  const seeMoreAll = recommendations.filter(({ node }) => {
    const moreTopic = node.frontmatter.topic
    const moreSlug = node.fields.slug
    return moreTopic === topic && moreSlug !== slug
  })
  const seeMore = seeMoreAll.slice(0, 4)

  return (
    <IndexLayout>
      <RecoTemplateWrapper>
        <LinkWrapper>
          <IconLink to="/recommendations" label="Back" />
        </LinkWrapper>
        <RecoTemplateInner>
          <RecoHeader>
            <RecoIcon iconURL={icon.publicURL} size="96px" isHeader borderRadius="120px" bgSize="50%" />
            <ScalesWrapper>
              <IconScale type="time" value={time} />
              <IconScale type="money" value={money} />
              <IconScale type="engagement" value={engagement} />
            </ScalesWrapper>
          </RecoHeader>
          <h1>{title}</h1>
          <Subtitle>{subtitle}</Subtitle>
          <Description>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Description>
          {!disableImplementationTip && <ImplementationHeader>Implementation tips</ImplementationHeader>}
          <MDWrapper>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
          </MDWrapper>
        </RecoTemplateInner>
        <SeeMoreWrapper>
          {seeMore.length > 0 && (
            <>
              <h2>See more recommendations</h2>
              <SeeMoreGrid>
                {seeMore.map(({ node }) => (
                  <Link key={`card_${node.frontmatter.title}`} to={`/recommendations${node.fields.slug}`}>
                    <RecoCard>
                      <RecoIcon iconURL={node.frontmatter.icon.publicURL} size="48px" borderRadius="120px" bgSize="50%" />
                      <RecoCardTitle>{node.frontmatter.title}</RecoCardTitle>
                      <RecoCardSubtitle>{node.frontmatter.subtitle}</RecoCardSubtitle>
                    </RecoCard>
                  </Link>
                ))}
              </SeeMoreGrid>
            </>
          )}
        </SeeMoreWrapper>
      </RecoTemplateWrapper>
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query RecommendationTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        time
        money
        engagement
        topic
        subtitle
        description
        disableImplementationTip
        icon {
          publicURL
        }
      }
      fields {
        slug
      }
    }
    allMarkdownRemark(filter: { fields: { type: { eq: "recommendations" } } }) {
      edges {
        node {
          frontmatter {
            title
            topic
            subtitle
            description
            icon {
              publicURL
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
