import styled from '@emotion/styled'
import { colors } from '../../styles/variables'

export const IconScaleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const ColorCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: linear-gradient(180deg, #db767c 0.01%, #a633c3 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
`
export const GreyCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${colors.plain.nuances3};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
`
